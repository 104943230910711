import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
// import Logo from '../images/logo.png';
import { logout } from '../utils';
import Logo from '../components/Logo';

export default function Dashboard() {
    const history = useHistory();
    const [videoData, setVideoData] = useState({});
    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('videoData'));
        
    //    const videoHtml =  data.oEmbed.html);

        setVideoData({
            video_id: data?.oEmbed.video_id,
            uri: data?.oEmbed.uri,
            width: data?.oEmbed.width,
            height: data?.oEmbed.height,
            title: data?.oEmbed.title,
            url: data?.url,
            html: createMarkup(data?.oEmbed.html)
        });
    }, [])

    function createMarkup(receivedHTML) {
        return {__html: receivedHTML};
      }

    const handleLogoutAction = () => {
        logout();
        history.replace('/');
    }

    console.log(videoData);

    return (
        <div className="container-fluid">
            <div className="row mb-1">
                <div className="col-md-12">
                    <div className="homeHeader">
                            <Link className="logout" to="#" onClick={handleLogoutAction}>Close</Link>
                    </div>
                    
                </div>
            </div>
            <div className="row" dangerouslySetInnerHTML={videoData?.html}>
            </div>
            
        </div>
    )
}
