
export const isLogin =  () => {
    const auth = localStorage.getItem('authorized');
    console.log('auth is',auth)
    if (auth === 'yes') {
        return true;
    }
    console.log('called')
    return false;
}

export const logout = () => {
    localStorage.removeItem('authorized');
    localStorage.removeItem('videoData');
}