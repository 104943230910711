import React from 'react'
import LogoImage from '../images/logo.png';

export default function Logo() {
    return (
        <div className="logo-section">
            <img src={LogoImage} height="130" alt="" />
        </div>
    )
}
