import React, { useState } from 'react'
// image import
// import Logo from '../images/logo.png';
import LoginImg from '../images/login-side-image.png';
import API_REQUESTS from '../config/config';
import {
    BrowserRouter as Router,
    useHistory,
} from "react-router-dom";
import '../App.css';
import Logo from '../components/Logo';

export default function Login() {
    let history = useHistory();
    const [video_password, setVideoPasswordValue] = useState('');

    const handlePassword = (event) => {
        setVideoPasswordValue(event.target.value)
    }
    const data = {
        access_code: video_password
    }

    /**
     * 
     * @param {*} event Form submit event
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        // alert(password)
        fetch(API_REQUESTS.VERIFY_USER_VIDEO, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
        .then(async (resp) => {
            if(resp.ok) {
                const response = await resp.json();
                localStorage.setItem('authorized', 'yes');
                localStorage.setItem('videoData', JSON.stringify(response.data));
                return   history.replace('/dashboard');
            } else {
                return history.replace('/wrong-password');
            }
        });
    }

    return (
        <div className="container">
            <Logo />
            <div className="v-align-center">
                <div className="row align-items-center">
                
                    <div className="col-md-6">
                        <div className="image-section">
                            <img src={LoginImg} alt="" />
                        </div>
                    </div>
                
                    <div className="col-md-6">
                        <div className="form-box">
                            <form onSubmit={handleSubmit}>
                                {/* <h3>Welcome to Social Chaos</h3> */}
                                <p>Vul wachtwoord in / Enter password</p>
                                <div className="form-group">
                                    <input autoComplete="off" type="password" onChange={e => handlePassword(e)} value={video_password} className="form-control" placeholder="Enter video password" />
                                </div>
                                <button type="submit" className="btn btn-primary btn-block">Enter</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
