import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PasswordError from "./pages/PasswordError";
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default function Routes() {
  return (
    <Router>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/" exact />
        <PublicRoute restricted={true} component={PasswordError} path="/wrong-password" exact />
        <PrivateRoute component={Dashboard} path="/dashboard" exact />
        <PublicRoute restricted={false} component={Login} path="*" />
      </Switch>
    </Router>
  );
}
