import React from 'react';
// import Logo from '../images/logo.png';
import Errorpageimage from '../images/wrong_password.jpg';
import { Link } from 'react-router-dom';
import Logo from '../components/Logo';

export default function PasswordError() {
    return (
        <div className="container">
            <Logo />
            <div className="row align-items-center">
                <div className="col-md-6 mx-auto">
                    <div className="error-page">
                        
                        <img src={Errorpageimage} height="500" alt="" />
                        {/* <h3><b>Oops..</b>
                            wrong password</h3> */}
                        <Link to="/">Click here to try again</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
